import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule, MatIconModule, MatMenuModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import {AppRoutingModule} from './app-routing.module';
import {RouterModule} from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { CurrentEventsComponent } from './components/current-events/current-events.component';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { UserResolver } from './components/current-events/current-events.resolver';
import { AuthGuard } from './guards/auth.guard';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { IntroComponent } from './components/intro/intro.component';
import { PersonnalComponent } from './components/personnal/personnal.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { IdeasComponent } from './components/ideas/ideas.component';
import { ClientsComponent } from './components/clients/clients.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    CurrentEventsComponent,
    IntroComponent,
    PersonnalComponent,
    PortfolioComponent,
    IdeasComponent,
    ClientsComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AppRoutingModule,
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    // Angular Material Modules
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [AuthService, UserService, UserResolver, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
