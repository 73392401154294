import { Component, OnInit } from '@angular/core';
import {ViewChild, ElementRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(public afAuth: AngularFireAuth){}

  ngOnInit() {
  }

  public onInquire(type: string) {
    if (type === 'LearnMore') {
      window.location.href = 'mailto:questions@truittdevelopment.com?subject=I\'m interested in learning more about your Apps';
    } else if (type === 'data' || type === 'ai') {
      window.location.href = 'mailto:data@truittdevelopment.com?subject=I\'m interested in learning more about what you do with' + type;
    } else {
      window.location.href = 'mailto:' + type.toLocaleLowerCase() +
      'apps@truittdevelopment.com?subject=I\'m interested in learning more about your ' + type + ' Apps';
    }
  }

  public onWebInquire() {
    window.location.href = 'mailto:webapps@truittdevelopment.com?subject=I\'m interested in learning more about your Web Apps';
  }

  public onAndroidInquire() {
    window.location.href = 'mailto:androidapps@truittdevelopment.com?subject=I\'m interested in learning more about your Android Apps';
  }

  public onIosInquire() {
    window.location.href = 'mailto:iosapps@truittdevelopment.com?subject=I\'m interested in learning more about your iOS Apps';
  }
}
