import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personnal',
  templateUrl: './personnal.component.html',
  styleUrls: ['./personnal.component.scss']
})
export class PersonnalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
