import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CurrentEventsComponent } from './components/current-events/current-events.component';
import { AuthGuard } from './guards/auth.guard';
import { UserResolver } from './components/current-events/current-events.resolver';
import { IntroComponent } from './components/intro/intro.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { PortfolioResolver } from './components/portfolio/portfolio.resolver';
import { PersonnalComponent } from './components/personnal/personnal.component';
import { MembersResolver } from './components/intro/members.resolver';
import { PersonnalResolver } from './components/personnal/personnal.resolver';
import { IdeasComponent } from './components/ideas/ideas.component';
import { ClientsComponent } from './components/clients/clients.component';

const routes: Routes = [
    { path: '', component: HomeComponent},
    { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
    { path: 'politics', component: CurrentEventsComponent, resolve: { data: UserResolver} },
    { path: 'members', component: IntroComponent, resolve: { data: MembersResolver} },
    { path: 'portfolio', component: PortfolioComponent, resolve: { data: PortfolioResolver}},
    { path: 'home', component: PersonnalComponent, resolve: { data: PersonnalResolver}},
    { path: 'ideas', component: IdeasComponent, resolve: { data: PersonnalResolver}},
    { path: 'clients', component: ClientsComponent, resolve: {data: MembersResolver }}
  ];

  @NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }
