import { Component, OnInit } from '@angular/core';
import { TaskModel } from 'src/app/models/task.model';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  company: string;
  jobTitle: string;
  logo: string;
  tasks: TaskModel[];
  progress: number;
  showDes: boolean;
  activeTask: TaskModel;

  constructor() { }

  ngOnInit() {
  }

  showDetails(task: TaskModel) {
    this.activeTask = task;
  }

  onQuestion() {
      window.location.href = 'mailto:projectstatus@truittdevelopment.com?subject=' + this.jobTitle + '-' + this.company;
  }
}
